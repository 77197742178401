import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

import CloseIcon from "@mui/icons-material/Close";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircle";
import { isWorksheetEmpty } from "./utils/excelUtils";

const useStyles = makeStyles({
  recommended_tile: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    paddingLeft: "4px",
    paddingRight: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    cursor: "pointer",
    backgroundColor: "white",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "25px",
    border: "1px solid #808080",
    fontSize: "12px",
  },
  recommendations: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  open_tiles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  tile_wrapper: {
    borderRadius: "10px",
    paddingBottom: "8px",
    backgroundColor: "#f0f0f0",
  },
  tile_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#004367",
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    "& h5": {
      fontSize: "16px",
      margin: "6px",
      padding: "0px",
    },
  },
  tile_logic: {
    padding: "4px",
  },
});

function RecommendedMosaic({ title, openMosaics, setOpenMosaics }) {
  const styles = useStyles();

  const addTile = (tileTitle) => {
    setOpenMosaics([...openMosaics, tileTitle]);
  };

  return (
    <div className={styles.recommended_tile} onClick={() => addTile(title)}>
      <AddCircleTwoToneIcon fontSize="small" sx={{ color: "#ffcf40", }} />
      {title}
    </div>
  );
}

function RecommendedMosaics({ openMosaics, setOpenMosaics, activeWorksheet }) {
  const styles = useStyles();
  const [recommendedMosaics, setRecommendedMosaics] = React.useState([]);

  React.useEffect(() => {
    isWorksheetEmpty(activeWorksheet).then((isEmpty) => {
      if (isEmpty) {
        setRecommendedMosaics(["DCF Analysis", "Multiples Analysis", "DDM Analysis"]);
      } else {
        setRecommendedMosaics([]);
      }
    });
  }, [activeWorksheet, openMosaics]);

  return (
    <div className={styles.recommendations}>
      {recommendedMosaics
        .filter((mosaic) => !openMosaics.includes(mosaic))
        .map((tile) => (
          <RecommendedMosaic key={tile} title={tile} openMosaics={openMosaics} setOpenMosaics={setOpenMosaics} />
        ))}
    </div>
  );
}

function MosaicHeader({ title, openMosaics, setOpenMosaics }) {
  const styles = useStyles();
  const dataTileLogo = require("../../../assets/data-tile-logo.png");
  const buildTileLogo = require("../../../assets/build-tile-logo.png");

  const closeTile = () => {
    setOpenMosaics(openMosaics.filter((tile) => tile !== title));
  };

  return (
    <div className={styles.tile_header}>
      {title === "Pull Historicals" && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={dataTileLogo} />
        </div>
      )}
      {(title === "Formula Inference" || title === "Add Forecasts") && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={buildTileLogo} />
        </div>
      )}
      <h5>{title}</h5>
      <div style={{ marginRight: "6px", marginTop: "6px", cursor: "pointer", marginLeft: "auto" }} onClick={closeTile}>
        <CloseIcon fontSize="medium" />
      </div>
    </div>
  );
}

function OpenMosaics({ openMosaics, setOpenMosaics }) {
  const styles = useStyles();
  const tilesMap = {};

  return (
    <div className={styles.open_tiles}>
      {openMosaics
        .filter((tile) => tile in tilesMap)
        .map((tile) => (
          <div key={tile} className={styles.tile_wrapper}>
            <MosaicHeader title={tile} openMosaics={openMosaics} setOpenMosaics={setOpenMosaics} />
            <div className={styles.tile_logic}>{tilesMap[tile]}</div>
          </div>
        ))}
    </div>
  );
}

export default function Mosaics({ activeWorksheet }) {
  const [openMosaics, setOpenMosaics] = React.useState([]);

  return (
    <div>
      <RecommendedMosaics openMosaics={openMosaics} setOpenMosaics={setOpenMosaics} activeWorksheet={activeWorksheet} />
      <OpenMosaics openMosaics={openMosaics} setOpenMosaics={setOpenMosaics} />
    </div>
  );
}
