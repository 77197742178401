import * as React from "react";
import { insertData } from "../utils/excelUtils";
import { ListOpenCloseIcon } from "../utils/ListOpenCloseIcon";

import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const relevantSources = {
  "Revenue Growth": [
    {
      source: require("../../../../assets/forbes-logo.png"),
      title: "AAPL Revenue Growth 2025 Prediction",
      link: "https://www.forbes.com/sites/investor-hub/article/apple-aapl-stock-2025-price-prediction",
      quote:
        "Based on estimates compiled by Seeking Alpha, Apple is projected to earn $415 billion in revenue for fiscal 2025",
    },
    {
      source: require("../../../../assets/simply-wallstreet.png"),
      title: "Apple",
      link: "https://simplywall.st/stocks/us/tech/nasdaq-aapl/apple/future",
      quote: "Apple is forecast to grow earnings and revenue by 10.2% and 6.5% per annum respectively.",
    },
  ],
  "Gross Profit Margin": [
    {
      source: require("../../../../assets/barrons-logo.png"),
      title: "Trump Promises Tariffs. Wall Street Is Debating What That Means for Apple",
      link: "https://www.barrons.com/articles/apple-stock-trump-tariffs-china-c8bcfe13",
      quote:
        "Most of Apple’s manufacturing is done in China, but the last time Trump was president, the iPhone maker was exempt from tariffs. If that changes this time, Jefferies analyst Edison Lee wrote that Apple gross margin could potentially be negatively impacted between 3% to 6.7%.",
    },
  ],
};

const assumptions = {
  "Income Statement": [
    "Revenue Growth",
    "Gross Profit Margin",
    "R&D Expenses Growth",
    "SG&A Expenses",
    "D&A",
    "Effective Tax Rate",
    "Weighted Average Shares Outstanding",
  ],
  "Balance Sheet": [
    "Cash And Cash Equivalents",
    "Short Term Investments",
    "A/R",
    "Inventory",
    "Other Current Assets",
    "A/P",
    "Deffered Revenue",
    "Long Term Investments Growth",
    "Tax Assets",
    "Other Non Current Assets",
    "Short Term Debt",
    "Tax Payables",
    "Other Current Liabilities",
    "Other Non Current Liabilities",
    "Common Stock",
    "Accumulated Other Comprehensive Income Loss",
  ],
  "Cash Flow Statement": [
    "Stock Based Compensation",
    "Other Non Cash Items",
    "Common Stock Repurchased",
    "Dividends Paid Growth",
    "Capital Expenditure",
  ],
};

function PartTitle({ title }) {
  return (
    <p style={{ margin: "0px", marginTop: "6px", marginBottom: "6px", fontSize: "16px" }}>
      <b>{title}</b>
    </p>
  );
}

function OwnValuesRow({ onAdd }) {
  const rowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    marginLeft: "6px",
    marginRight: "6px",
    marginBottom: "6px",
    justifyContent: "space-between",
  };
  const inputStyle: React.CSSProperties = {
    width: "15%",
    height: "20px",
    borderRadius: "4px",
    border: "1px solid #004367",
    textAlign: "center",
  };
  const textStyle: React.CSSProperties = {
    margin: "0px",
    marginTop: "4px",
    fontSize: "13px",
  };
  return (
    <div style={rowStyle}>
      <p style={textStyle}>Value (%):</p>
      <input style={inputStyle} type="number" placeholder="6.0" />
      <p style={textStyle}>Period:</p>
      <input style={inputStyle} type="number" min="2025" max="2029" step="1" />
      <p style={textStyle}> - </p>
      <input style={inputStyle} type="number" min="2025" max="2029" step="1" />
      <div onClick={onAdd}>
        <AddCircleIcon fontSize="small" sx={{ color: "#004367", cursor: "pointer" }} />
      </div>
    </div>
  );
}

function ValuesSettings({ forecastSelected }) {
  if (forecastSelected === "own") {
    const [wasClicked, setWasClicked] = React.useState(false);
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "8px" }}>
        <OwnValuesRow onAdd={() => setWasClicked(true)} />
        {wasClicked && <OwnValuesRow onAdd={() => {}} />}
      </div>
    );
  } else if (forecastSelected === "linreg") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "8px" }}>
        <p
          style={{
            color: "green",
            fontSize: "12px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            textAlign: "center",
          }}
        >
          Great Fit - Linear Regression predicts the metric with 91% accuracy
        </p>
      </div>
    );
  } else if (forecastSelected === "avg") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "8px" }}>
        <label style={{ marginLeft: "6px", fontSize: "12px" }}># of years:</label>
        <input style={{ width: "15%", fontSize: "13px" }} type="number" min={1} max={20} step={1}></input>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function Assumption({ name, recommendationsUsed }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [wasOpened, setWasOpened] = React.useState(false);
  const [forecastSelected, setForecastSelected] = React.useState("");

  React.useEffect(() => {
    if (isOpen) {
      setWasOpened(true);
    }
  }, [isOpen]);

  return (
    <div style={{ backgroundColor: "#f9f9fad0", borderRadius: "10px", border: "1px solid #004367" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: "0px", marginTop: "4px", marginBottom: "4px", paddingLeft: "6px", fontSize: "14px" }}>
          {isOpen ? <b>{name}</b> : name}
        </p>
        {(!wasOpened || isOpen) && !recommendationsUsed ? (
          <BorderColorIcon fontSize="small" style={{ marginLeft: "6px", marginTop: "3px", color: "#004367" }} />
        ) : (
          <CheckCircleIcon fontSize="small" style={{ marginLeft: "6px", marginTop: "3px", color: "#004367" }} />
        )}
        <div style={{ marginTop: "2px", marginRight: "4px", marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      {isOpen && (
        <div>
          {/** Relevant Information **/}
          {relevantSources[name] && (
            <div>
              <p style={{ paddingLeft: "6px", fontSize: "13px" }}>
                <b>Relevant Information</b>
              </p>
              <ul style={{ listStyleType: "none", margin: "0px", padding: "0px", marginBottom: "6px" }}>
                {relevantSources[name].map((article) => {
                  return (
                    <li
                      style={{
                        marginLeft: "12px",
                        marginBottom: "4px",
                        padding: "0px",
                        paddingRight: "8px",
                        fontSize: "13px",
                      }}
                    >
                      <img style={{ height: "12px", color: "white", marginRight: "6px" }} src={article.source} />
                      <a href={article.link} target="_blank">
                        <b>{article.title}</b>
                      </a>
                      <i> - "{article.quote}" </i>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {/** Forecast Method **/}
          <div>
            <p style={{ margin: "0px", padding: "0px", marginBottom: "4px", paddingLeft: "6px", fontSize: "13px" }}>
              <b>Forecast Method</b>
            </p>
            <select
              name="selectedDataSource"
              style={{ height: "24px", display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "4px" }}
              onChange={(e) => setForecastSelected(e.target.value)}
            >
              <option value="" disabled selected>
                Select your option
              </option>
              <option value="own">Own Values</option>
              <option value="linreg">Linear Regression</option>
              <option value="avg">X Years Average</option>
              <option value="same">Same as Last Period</option>
            </select>
          </div>
          {/** Values Settings **/}
          <div>
            {forecastSelected !== "" && (
              <div>
                <ValuesSettings forecastSelected={forecastSelected} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function AssumptionList({ idx, statement, statementAssumptions }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [recommendationsUsed, setRecommendationsUsed] = React.useState(false);

  return (
    <div key={idx}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "14px", margin: "2px", padding: "2px" }}>
          <b>{statement}</b>
        </p>
        <div style={{ cursor: "pointer" }} onClick={() => setRecommendationsUsed(true)}>
          <AutoAwesomeIcon fontSize="small" />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      {isOpen && (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {statementAssumptions.map((assumption) => (
            <Assumption name={assumption} recommendationsUsed={recommendationsUsed} />
          ))}
        </div>
      )}
    </div>
  );
}

export function AddForecasts({ activeWorksheet }) {
  const [isAnnual, setIsAnnual] = React.useState(true);
  const [isWaiting, setIsWaiting] = React.useState(false);
  let horizonPickingString = "Number of " + (isAnnual ? "years" : "quarters") + " forward";

  const loadForecasts = async () => {
    let data = require("../../../../assets/aapl/aapl_with_forecasts.json");
    setIsWaiting(true);
    await insertData(data["AAPL"], activeWorksheet);
    setTimeout(() => {
      setIsWaiting(false);
    }, 1000);
  };

  return (
    <div>
      {/* Set Frequency */}
      <PartTitle title="Data frequency" />
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
        <label>
          <input
            type="radio"
            name="periods"
            value="q"
            defaultChecked={!isAnnual}
            onClick={() => setIsAnnual(!isAnnual)}
          />
          Quarterly
        </label>
        <label>
          <input
            type="radio"
            name="periods"
            value="a"
            defaultChecked={isAnnual}
            onClick={() => setIsAnnual(!isAnnual)}
          />
          Annual
        </label>
      </div>

      {/* Set Horizon Length */}
      <PartTitle title={horizonPickingString} />
      <input
        id="ticketNum"
        style={{
          width: "15%",
          height: "24px",
          borderRadius: "10px",
          border: "1px solid #004367",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
        }}
        type="number"
        placeholder="3"
        min={1}
        max={10}
      />

      {/* Set Required Assumptions */}
      <PartTitle title="Required Assumptions" />
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {Object.keys(assumptions).map((statement, idx) => (
          <AssumptionList idx={idx} statement={statement} statementAssumptions={assumptions[statement]} />
        ))}
      </div>

      {/* Run */}
      <div style={{ marginTop: "8px", marginLeft: "auto", marginRight: "auto", display: "flex", alignItems: "center" }}>
        {isWaiting ? (
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <CircularProgress />
          </div>
        ) : (
          <button
            style={{
              color: "white",
              backgroundColor: "#004367",
              borderRadius: "25px",
              border: "0px",
              width: "40%",
              height: "34px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              cursor: "pointer",
            }}
            onClick={loadForecasts}
          >
            Add Forecasts
          </button>
        )}
      </div>
    </div>
  );
}
