import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

import DebugTab from "./debugging/DebugTab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import VisualizersPanel from "./debugging/VisualizersPanel";
import HistoricalsPuller from "./historicals/HistoricalsPuller";
import { FormulaInference } from "./formula_inference/FormulaInference";
import { AddForecasts } from "./add_forecasts/AddForecasts";
import { DCF } from "./dcf/dcf";
import { isWorksheetEmpty } from "./utils/excelUtils";

const useStyles = makeStyles({
  recommended_tile: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    paddingLeft: "4px",
    paddingRight: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    cursor: "pointer",
    backgroundColor: "white",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "25px",
    border: "1px solid #808080",
    fontSize: "12px",
  },
  recommendations: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  open_tiles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  tile_wrapper: {
    borderRadius: "10px",
    paddingBottom: "8px",
    backgroundColor: "#f0f0f0",
  },
  tile_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#004367",
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    "& h5": {
      fontSize: "16px",
      margin: "6px",
      padding: "0px",
    },
  },
  tile_logic: {
    padding: "4px",
  },
});

function RecommendedTile({ title, openTiles, setOpenTiles, openedTiles, setOpenedTiles }) {
  const styles = useStyles();

  const addTile = (tileTitle) => {
    if (!openedTiles.includes(tileTitle)) {
      setOpenedTiles([...openedTiles, tileTitle]);
    }
    setOpenTiles([...openTiles, tileTitle]);
  };

  return (
    <div className={styles.recommended_tile} onClick={() => addTile(title)}>
      <AddCircleIcon fontSize="small" sx={{ color: "#004367" }} />
      {title}
    </div>
  );
}

function RecommendedTiles({ openTiles, setOpenTiles, activeWorksheet, openedTiles, setOpenedTiles }) {
  const styles = useStyles();
  const [recommendedTiles, setRecommendedTiles] = React.useState([]);

  React.useEffect(() => {
    isWorksheetEmpty(activeWorksheet).then((isEmpty) => {
      if (openedTiles.length === 0 && isEmpty) {
        setRecommendedTiles(["Pull Historicals", "Load Existing Model", "Load Template"]);
      } else if (openedTiles.includes("Add Forecasts")) {
        setRecommendedTiles(["DCF Valuation", "Debugger"]);
      } else if (openedTiles.includes("Pull Historicals")) {
        setRecommendedTiles(["Visualizers", "Debugger", "Add Forecasts", "Versions", "Formula Inference"]);
      } else if (!isEmpty) {
        setRecommendedTiles(["Debugger", "Convert to Firm's Format"]);
      }
    });
  }, [activeWorksheet, openedTiles]);

  return (
    <div className={styles.recommendations}>
      {recommendedTiles
        .filter((tile) => !openedTiles.includes(tile))
        .map((tile) => (
          <RecommendedTile
            key={tile}
            title={tile}
            openTiles={openTiles}
            setOpenTiles={setOpenTiles}
            openedTiles={openedTiles}
            setOpenedTiles={setOpenedTiles}
          />
        ))}
    </div>
  );
}

function TileHeader({ title, openTiles, setOpenTiles }) {
  const styles = useStyles();
  const dataTileLogo = require("../../../assets/data-tile-logo.png");
  const buildTileLogo = require("../../../assets/build-tile-logo.png");

  const closeTile = () => {
    setOpenTiles(openTiles.filter((tile) => tile !== title));
  };

  return (
    <div className={styles.tile_header}>
      {title === "Pull Historicals" && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={dataTileLogo} />
        </div>
      )}
      {(title === "Formula Inference" || title === "Add Forecasts" || title === "DCF Valuation") && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={buildTileLogo} />
        </div>
      )}
      <h5>{title}</h5>
      <div style={{ marginRight: "6px", marginTop: "6px", cursor: "pointer", marginLeft: "auto" }} onClick={closeTile}>
        <CloseIcon fontSize="medium" />
      </div>
    </div>
  );
}

function OpenTiles({ openTiles, setOpenTiles, activeWorksheet, addColors, removeColors }) {
  const styles = useStyles();

  const tilesMap = {
    Debugger: <DebugTab activeWorksheet={activeWorksheet} addColors={addColors} removeColors={removeColors} />,
    Visualizers: (
      <VisualizersPanel activeWorksheet={activeWorksheet} addColors={addColors} removeColors={removeColors} />
    ),
    "Model Builder": <div>Model Builder</div>,
    "Add Forecasts": <AddForecasts activeWorksheet={activeWorksheet} />,
    Versions: <div>Versions</div>,
    "Pull Historicals": <HistoricalsPuller activeWorksheet={activeWorksheet} />,
    "Formula Inference": <FormulaInference activeWorksheet={activeWorksheet} />,
    "DCF Valuation": <DCF activeWorksheet={activeWorksheet} />,
  };

  return (
    <div className={styles.open_tiles}>
      {openTiles
        .filter((tile) => tile in tilesMap)
        .map((tile) => (
          <div key={tile} className={styles.tile_wrapper}>
            <TileHeader title={tile} openTiles={openTiles} setOpenTiles={setOpenTiles} />
            <div className={styles.tile_logic}>{tilesMap[tile]}</div>
          </div>
        ))}
    </div>
  );
}

export default function Tiles({ activeWorksheet, addColors, removeColors }) {
  const [openTiles, setOpenTiles] = React.useState([]);
  const [openedTiles, setOpenedTiles] = React.useState([]); // History record of tiles that have been opened

  return (
    <div>
      <RecommendedTiles
        openTiles={openTiles}
        setOpenTiles={setOpenTiles}
        activeWorksheet={activeWorksheet}
        openedTiles={openedTiles}
        setOpenedTiles={setOpenedTiles}
      />
      <OpenTiles
        openTiles={openTiles}
        setOpenTiles={setOpenTiles}
        activeWorksheet={activeWorksheet}
        addColors={addColors}
        removeColors={removeColors}
      />
    </div>
  );
}
