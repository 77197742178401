import * as React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function CheckboxSelection() {
  return (
    <FormGroup sx={{margin: "0", padding: "0", paddingLeft: "12px"}}>
      <FormControlLabel sx={{margin: "0", padding: "0"}} control={<Checkbox sx={{margin: "0", padding: "0"}}/>} label="Income Statement" />
      <FormControlLabel sx={{margin: "0", padding: "0"}} control={<Checkbox sx={{margin: "0", padding: "0"}}/>} label="Balance Sheet" />
      <FormControlLabel sx={{margin: "0", padding: "0"}} control={<Checkbox sx={{margin: "0", padding: "0"}}/>} label="Cash Flow" />
    </FormGroup>
  );
}


