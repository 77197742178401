import * as React from "react";
import { insertData } from "../utils/excelUtils";
import { ListOpenCloseIcon } from "../utils/ListOpenCloseIcon";

import CircularProgress from "@mui/material/CircularProgress";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const assumptions = {
  "Risk-Free Rate": { value: "4.75%", support: "FRED 30 Year Treasury Rate" },
  "Equity Premium": { value: "5.00%", support: "Common Choice" },
  "Equity Beta": { value: "1.17", support: "https://www.barrons.com/market-data/stocks/aapl" },
  "Effective Interest Rate": { value: "5.77%", support: "Debt Analysis Model" },
  "Terminal Growth Rate": { value: "4.25%", support: "https://www.dbs.com/content/article/pdf/US_clover/Apple.pdf" },
  "Target Leverage": { value: 0.25, support: "Debt Analysis Model" },
  "Effective Tax Rate": { value: "16.59%", support: "See Income Statement Assumption" },
};

function Assumption({ name, assumptionValue, support }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [wasOpened, setWasOpened] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setWasOpened(true);
    }
  }, [isOpen]);

  return (
    <div style={{ backgroundColor: "#f9f9fad0", borderRadius: "10px", border: "1px solid #004367" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: "0px", marginTop: "4px", marginBottom: "4px", paddingLeft: "6px", fontSize: "14px" }}>
          {isOpen ? <b>{name}</b> : name}
        </p>
        {!wasOpened || isOpen ? (
          <BorderColorIcon fontSize="small" style={{ marginLeft: "6px", marginTop: "3px", color: "#004367" }} />
        ) : (
          <CheckCircleIcon fontSize="small" style={{ marginLeft: "6px", marginTop: "3px", color: "#004367" }} />
        )}
        <div style={{ marginTop: "2px", marginRight: "4px", marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      {isOpen && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
            <p style={{ margin: "0px", padding: "0px", marginBottom: "4px", paddingLeft: "6px", fontSize: "12px" }}>
              <b>Recommended Value</b>
            </p>
            <label>
              <input
                name={name}
                type="text"
                defaultValue={assumptionValue}
                style={{ width: "30%", borderRadius: "10px", textAlign: "center" }}
              />
            </label>
          </div>
          <div>
            <p style={{ margin: "0px", padding: "0px", marginBottom: "4px", paddingLeft: "6px", fontSize: "10px" }}>
              <b>Support </b>
              <a href={support} target="_blank">
                {support}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function AssumptionList() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          <b>Required Assumptions</b>
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {Object.keys(assumptions).map((assumption) => (
          <Assumption
            name={assumption}
            assumptionValue={assumptions[assumption].value}
            support={assumptions[assumption].support}
          />
        ))}
      </div>
    </div>
  );
}

export function DCF({ activeWorksheet }) {
  const [isWaiting, setIsWaiting] = React.useState(false);

  const loadValuation = async () => {
    let data = require("../../../../assets/aapl/aapl_full.json");
    setIsWaiting(true);
    await insertData(data["AAPL"], activeWorksheet);
    setTimeout(() => {
      setIsWaiting(false);
    }, 1000);
  };

  return (
    <div>
      {/* Set Required Assumptions */}
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <AssumptionList />
      </div>

      {/* Run */}
      <div style={{ marginTop: "8px", marginLeft: "auto", marginRight: "auto", display: "flex", alignItems: "center" }}>
        {isWaiting ? (
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <CircularProgress />
          </div>
        ) : (
          <button
            style={{
              color: "white",
              backgroundColor: "#004367",
              borderRadius: "25px",
              border: "0px",
              width: "40%",
              height: "34px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              cursor: "pointer",
            }}
            onClick={loadValuation}
          >
            Add Valuation
          </button>
        )}
      </div>
    </div>
  );
}
