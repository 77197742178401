import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import SearchBar, { statements } from "./SearchBar";
import CircularProgress from "@mui/material/CircularProgress";

import { insertData } from "../utils/excelUtils";
import CheckboxSelection from "./CheckboxSelection";

const useStyles = makeStyles({
  hist_importer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
  },
});

export default function HistoricalsPuller({ activeWorksheet }) {
  const styles = useStyles();
  const [isWaiting, setIsWaiting] = React.useState(false);

  const loadHistoricals = async () => {
    let data = require("../../../../assets/aapl/aapl_after_pull.json");
    setIsWaiting(true);
    await insertData(data["AAPL"], activeWorksheet);
    setTimeout(() => {
      setIsWaiting(false);
    }, 1000);
  };

  return (
    <div className={styles.hist_importer}>
      <SearchBar />
      {/* <SearchBar options={statements} label={"Choose Statements"} /> */}
      <p style={{ marginBottom: "2px", paddingBottom: "4px" }}>
        <b>Select Statements</b>
      </p>
      <CheckboxSelection />
      <div>
        <p style={{ marginBottom: "2px", paddingBottom: "4px" }}>
          <b>Choose Data Frequency</b>
        </p>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
          <label>
            <input type="radio" name="periods" value="q" defaultChecked={true} />
            Quarterly
          </label>
          <label>
            <input type="radio" name="periods" value="a" />
            Annual
          </label>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
        <p style={{ margin: "4px" }}>
          <b>Available Sources</b>
        </p>
        <select
          name="selectedDataSource"
          style={{ height: "30px", width: "40%", borderRadius: "10px", marginLeft: "auto", marginRight: "auto" }}
        >
          <option value="sec">BamSEC</option>
          <option value="va">Visible Alpha</option>
          <option value="fastset">FactSet</option>
          <option value="bloomberg">Bloomberg</option>
        </select>
      </div>
      {isWaiting ? (
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <CircularProgress />
        </div>
      ) : (
        <button
          style={{
            color: "white",
            backgroundColor: "#004367",
            borderRadius: "25px",
            border: "0px",
            width: "25%",
            height: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            cursor: "pointer",
          }}
          onClick={loadHistoricals}
        >
          Pull Data
        </button>
      )}
    </div>
  );
}
