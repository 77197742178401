import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
  },
  search_box: {
    width: "100%",
    padding: "8px",
    borderRadius: "25px",
    border: "1px solid #808080",
    fontSize: "15px",
  },
});

export default function SearchBar({ placeholder }) {
  const styles = useStyles();
  return (
    <div className={styles.search}>
      <input className={styles.search_box} type="text" placeholder={placeholder} />
    </div>
  );
}
