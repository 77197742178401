import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import DiamondIcon from "@mui/icons-material/Diamond";
import FeedbackPopup from "./FeedbackPopup";
import WizzoFooterButton from "./utils/WizzoButton";
import LogoutButton from "./utils/LogoutButton";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { addWorksheetChangeEventListeners, getActiveWorksheetName } from "./utils/excelUtils";
import { addColorsLayer, removeColorsLayer } from "./debugging/utils";
import Tiles from "./Tiles";
import Mosaics from "./Mosaics";
import SearchBar from "./SearchBar";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
  },
  buttons_bar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    marginTop: "auto",
    paddingTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
  },
});

const ButtonsBar = ({ userName, userEmail }) => {
  const styles = useStyles();
  const [isFeedbackOpen, setIsFeedbackOpen] = React.useState(false);
  return (
    <div style={{ marginTop: "auto" }}>
      {isFeedbackOpen && (
        <FeedbackPopup setIsFeedbackOpen={setIsFeedbackOpen} userName={userName} userEmail={userEmail} />
      )}
      <div className={styles.buttons_bar}>
        <WizzoFooterButton
          buttonText="Wizzo Pro"
          buttonIcon={<DiamondIcon color="inherit" />}
          action={() => {
            window.open(
              "https://app.visily.ai/projects/9bb26e2d-e5c3-404d-a080-16e2ff3f767a/boards/1438605/presenter?play-mode=Prototype",
              "_blank"
            );
          }}
        />
        <WizzoFooterButton
          buttonText="Feedback"
          buttonIcon={<AddCommentIcon color="inherit" />}
          action={() => {
            setIsFeedbackOpen(true);
          }}
        />
        <LogoutButton />
      </div>
    </div>
  );
};

export default function AppContent({ user }) {
  const styles = useStyles();
  const [activeWorksheet, setActiveWorksheet] = React.useState("");
  const [bckColorsMemory, setBckColorsMemory] = React.useState({});
  const bckColorsMemoryRef = React.useRef(bckColorsMemory);

  const updateActiveWorksheet = () => {
    getActiveWorksheetName().then((activeWorksheetName) => {
      setActiveWorksheet(activeWorksheetName);
    });
  };
  const addColors = (newColors) => setBckColorsMemory(addColorsLayer(bckColorsMemoryRef.current, newColors));
  const removeColors = (cells, colorToRemove) => {
    const [updatedState, newTopLayer] = removeColorsLayer(bckColorsMemoryRef.current, cells, colorToRemove);
    setBckColorsMemory(updatedState);
    return newTopLayer;
  };

  React.useEffect(() => {
    bckColorsMemoryRef.current = bckColorsMemory;
  }, [bckColorsMemory]);

  React.useEffect(() => {
    updateActiveWorksheet();
    addWorksheetChangeEventListeners(updateActiveWorksheet);
  }, []);

  return (
    <div className={styles.content}>
      <SearchBar placeholder="Search Tiles or Mosaics" />
      <Tiles activeWorksheet={activeWorksheet} addColors={addColors} removeColors={removeColors} />
      <Mosaics activeWorksheet={activeWorksheet} />
      <ButtonsBar userName={user?.given_name || "Guest"} userEmail={user?.email || "N/A"} />
    </div>
  );
}
