import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { insertData } from "../utils/excelUtils";
import CircularProgress from "@mui/material/CircularProgress";
import { ListOpenCloseIcon } from "../utils/ListOpenCloseIcon";

const useStyles = makeStyles({
  formulas_comp: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
  },
});

const formulas = {
  "Income Statement": [
    { itemName: "Gross Profit", formula: "Revenue-Cost Of Revenue" },
    { itemName: "Gross Profit Margin", formula: "Gross Profit/Revenue" },
    { itemName: "Cost And Expenses", formula: "Cost Of Revenue + Operating Expenses" },
    { itemName: "EBITDA", formula: "Revenue - Cost And Expenses + Depreciation And Amortization" },
    { itemName: "EBITDA Ratio", formula: "EBITDA/Revenue" },
    { itemName: "Operating Income", formula: "Revenue - Cost And Expenses" },
    { itemName: "Operating Income Margin", formula: "Operating Income/Revenue" },
    { itemName: "Income Before Tax", formula: "Operating Income + Total Other Income Expenses Net" },
    { itemName: "Net Income", formula: "Income Before Tax - Income Before Tax" },
    { itemName: "Net Income Margin", formula: "Net Income/Revenue" },
    { itemName: "Earnings Per Share", formula: "Net Income/Weighted Average Shares Outstanding" },
    { itemName: "Earnings Per Share Diluted", formula: "Net Income/Weighted Average Shares Outstanding Diluted" },
  ],
  "Balance Sheet": [
    {
      itemName: "Total Current Assets",
      formula:
        "Cash And Cash Equivalents + Short Term Investments + Net Receivables + Inventory + Other Current Assets",
    },
    {
      itemName: "Total Current Assets",
      formula:
        "Cash And Cash Equivalents + Short Term Investments + Net Receivables + Inventory + Other Current Assets",
    },
    {
      itemName: "Property Plant Equipment Net",
      formula: "Last Year Value - Capital Expenditure - Depreciation And Amortization",
    },
    {
      itemName: "Total Non Current Assets",
      formula: "Property Plant Equipment Net + Long Term Investments + Tax Assets + Other Non Current Assets",
    },
    { itemName: "Total Assets", formula: "Total Current Assets + Total Non Current Assets" },
    {
      itemName: "Total Current Liabilities",
      formula: "Accounts Payable + Short Term Debt + Tax Payables + Deferred Revenue + Other Current Liabilities",
    },
    { itemName: "Total Non Current Liabilities", formula: "Long Term Debt + Other Non Current Liabilities" },
    { itemName: "Total Liabilities", formula: "Total Current Liabilities + Total Non Current Liabilities" },
    { itemName: "Common Stock", formula: "Last Year Value + Stock Based Compensation" },
    {
      itemName: "Retained Earnings",
      formula: "Last Year Value + Net Income + Net Cash Used Provided By Financing Activities",
    },
    {
      itemName: "Total Stockholders Equity",
      formula: "Common Stock + Retained Earnings + Accumulated Other Comprehensive Income Loss",
    },
    { itemName: "Total Liabilities And Stockholders Equity", formula: "Total Liabilities + Total Stockholders Equity" },
    { itemName: "Total Investments", formula: "Short Term Investments + Long Term Investments" },
    { itemName: "Total Debt", formula: "Short Term Debt + Long Term Debt" },
    { itemName: "Net Debt", formula: "Total Debt - Cash And Cash Equivalents" },
  ],
  "Cash Flow Statement": [
    { itemName: "Net Income", formula: "Net Income (Income Statement)" },
    { itemName: "Depreciation And Amortization", formula: "Depreciation And Amortization (Income Statement)" },
    {
      itemName: "Non-cash Expenses",
      formula: "Depreciation And Amortization + Stock Based Compensation + Other Non Cash Items",
    },
    { itemName: "Accounts Receivables (change)", formula: "Accounts Receivables - Last Year Accounts Receivables" },
    { itemName: "Inventory (change)", formula: "Inventory - Last Year Inventory" },
    { itemName: "Accounts Payable (change)", formula: "Accounts Payable - Last Year Accounts Payable" },
    {
      itemName: "Other Working Capital (change)",
      formula: "(Current Assets - Current Liabilities) - (Last Year Current Assets - Last Year Current Liabilities)",
    },
    {
      itemName: "Net Cash Flow From Operating Activities",
      formula: "(Current Assets - Current Liabilities) - (Last Year Current Assets - Last Year Current Liabilities)",
    },
  ],
};

function IdentifiedFormulaItem({ itemName, formula }) {
  return (
    <Tooltip title={formula}>
      <div style={{ margin: "2px" }}>
        <Checkbox sx={{ margin: "0px", padding: "0px" }} defaultChecked={true} />
        {itemName}
      </div>
    </Tooltip>
  );
}

function FormulasList({ idx, statement, statementFormulas }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div key={idx}>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <p><b>{statement}</b></p>
        <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      {isOpen &&
        statementFormulas.map((formula) => (
          <IdentifiedFormulaItem itemName={formula.itemName} formula={formula.formula} />
        ))}
    </div>
  );
}

export function FormulaInference({ activeWorksheet }) {
  const styles = useStyles();
  const [isWaiting, setIsWaiting] = React.useState(false);

  const loadFormulas = async () => {
    let data = require("../../../../assets/aapl/aapl_after_formula_completion.json");
    setIsWaiting(true);
    await insertData(data["AAPL"], activeWorksheet);
    setTimeout(() => {
      setIsWaiting(false);
    }, 1000);
  };

  return (
    <div className={styles.formulas_comp}>
      <p style={{ margin: "0px" }}>
        <b>Identified Formulas</b>
      </p>
      <div style={{ fontSize: "14px", alignItems: "left" }}>
        {Object.keys(formulas).map((statement, idx) => (
          <FormulasList idx={idx} statement={statement} statementFormulas={formulas[statement]} />
        ))}
      </div>
      {isWaiting ? (
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <CircularProgress />
        </div>
      ) : (
        <button
          style={{
            color: "white",
            backgroundColor: "#004367",
            borderRadius: "25px",
            border: "0px",
            width: "40%",
            height: "34px",
            marginLeft: "auto",
            marginRight: "auto",
            cursor: "pointer",
          }}
          onClick={loadFormulas}
        >
          Replace values with formulas
        </button>
      )}
    </div>
  );
}
